import React from 'react';
import Console from 'react-console-component';
import 'react-console-component/main.css';
import './App.css';

class App extends React.Component {
    state = { count: 0 };

    echo = (text) => {
        this.setState({
            count: this.state.count + 1
        });
        this.refs.console.log(text);
        this.refs.console.return();
    }

    whoami = () => {
        fetch("https://icanhazip.com/")
            .then(res => res.text())
            .then(data => {
                this.refs.console.log(data);
                this.refs.console.return();
            });
    }

    handler = (raw) => {
        const fullcmd = raw.match(/\b(\S+)/g);

        if (fullcmd == null) { this.echo("err"); return; }

        if (fullcmd.length > 0) {
            const cmd = fullcmd.slice(0, 1)[0];
            const params = fullcmd.slice(1);

            switch(cmd) {
                case "echo": this.echo(params.join(" ")); break;
                case "whoami": this.whoami(); break;

                default: this.echo("err");
            }
        } else {
            this.echo("err");
        }
    }

    render() {
        const { count } = this.state;

        return (
            <div id="app" className="app">
                <header className="App-header">
                    <Console 
                        ref="console"
                        welcomeMessage="7c0"
                        handler={this.handler}
                        autofocus={true}
                        promptLabel={count + "> "}
                    />
                </header>
            </div>
        );
    }
}

export default App;
